body {
  scroll-behavior: auto;
  min-height: 100%;
}
html {
  scroll-behavior: auto;
  height: fill-available;
  height: -webkit-fill-available;
}

#root, .App, .subApp, #anonymSwiitchenWrapper {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

::-webkit-scrollbar {
  width: 3px;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-foreground);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
  border-radius: 3px;
}

:root {
  --scrollbar-foreground: #383737; /*Custom Farbe*/
  --scrollbar-background: #717172; /*Custom Farbe*/
}

.App {
  text-align: center;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

#panel-header {
  min-height: 0 !important;
}

.Mui-expanded {
  margin: 0 !important;
}

/* new */

.App {
  display: flex;
  flex-direction: column;
}

#anonymSwiitchenWrapper {
  /*overflow: hidden !important;*/
}

footer {
  margin-top: auto;
  text-align: center;
  height: 300px;
}

#logoHeader .st0,
#logoFooter .st0 {
  fill: #00374e;
}

#logoHeaderWhite .st0 {
  fill: white;
}
/*#logoHeader .st1 {*/
/*  !*fill: #00374e;*!*/
/*}*/




#logoArbeitgeber1:not(.stickyButtonClick) #leftDingens,
#blauweiß #leftDingens {
  transition: all 0.3s ease;
  transform: translateY(0%);
}

#logoArbeitgeber1:not(.stickyButtonClick):hover #leftDingens,
#blauweiß:hover #leftDingens {
  transform: translateY(54%);
}

#logoArbeitgeber1:not(.stickyButtonClick) #rightDingens,
#blauweiß #rightDingens {
  transition: all 0.3s ease;
  transform: translateY(0%);
}

#logoArbeitgeber1:not(.stickyButtonClick):hover #rightDingens,
#blauweiß:hover #rightDingens {
  transform: translateY(-54%);
}


#logoArbeitgeber1 .stickyButtonClick #leftDingens {
  transform: translateY(54%);
}

#logoArbeitgeber1 .stickyButtonClick #rightDingens {
  transform: translateY(-54%);
}

@keyframes moveDown{
  0% {
    transform:translateY(0);
  }
  50% {
    transform:translateY(54%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveUp{
  0% {
    transform:translateY(0);
  }
  50% {
    transform:translateY(-54%);
  }
  100% {
    transform:translateY(0);
  }
}

@keyframes rotateCustom{
  0% {
    transform:rotate(0);
  }
  50% {
    transform:rotate(180deg);
  }
  100% {
    transform:rotate(360deg);
  }
}

#testLogo {
  animation:rotateCustom 4s infinite;
}

#testLogo #leftDingens {
  animation:moveDown 1s infinite;
}

#testLogo #rightDingens {
  animation:moveUp 1s infinite;
}






#logoHeader #leftDingens,
#logoHeaderWhite #leftDingens,
#logoFooter #leftDingens {
  transition: all 0.3s ease;
  transform: translateY(-83%);
}

#logoHeader:hover #leftDingens,
#logoHeaderWhite:hover #leftDingens,
#logoFooter:hover #leftDingens {
  transform: translateY(-43%);
}

#logoHeader #rightDingens,
#logoHeaderWhite #rightDingens,
#logoFooter #rightDingens {
  transition: all 0.3s ease;
  transform: translateY(0%);
}

#logoHeader:hover #rightDingens,
#logoHeaderWhite:hover #rightDingens,
#logoFooter:hover #rightDingens {
  transform: translateY(-40%);
}

.MuiTouchRipple-root {
  /*padding: 0 !important;*/
}

.loginSvg .cls-1 {
  fill: #00374e;
}
.menuIcon {
  fill: #00374e !important;
}
.loginSvgWhite .cls-1 {
  fill: white;
}
.menuIconWhite {
  fill: white !important;
}

.menuIcon,
.menuIconWhite {
  transition: none !important;
}




#hamburger-menu {

}
#hamburger-menu.open {
  visibility: visible;
  right: 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.fadeInOut {
  transition: all 0.2s ease-in-out;
}



.weg {
  transform: translateX(50%);
  opacity: 0;
}


.fadeIn {
  transform: translateX(0%) !important;
  opacity: 1;
}

.fadeOut {
  transform: translateX(-50%);
  opacity: 0;
}


.squareButtonLeft .MuiTouchRipple-child,
.squareButtonRight .MuiTouchRipple-child {
  border-radius: 0 !important;
}


@media screen and (max-width: 712px) {
  .squareButtonLeft {
    padding-bottom: 1px !important;
  }
  .squareButtonRight {
    top: 1px !important;
  }
}
@media screen and (min-width: 712px) {
  .squareButtonLeft {
    padding-bottom: 2px !important;
  }
}


.wegUp {
  transform: translateY(100%);
  opacity: 0;
}


.fadeUp {
  transform: translateY(0%) !important;
  opacity: 1;
}

.wegLeft {
  transform: translateX(20%);
  opacity: 0;
}

.fadeLeft {
  transform: translateX(-20%) !important;
  opacity: 0;
  /*content-visibility: hidden;*/
  /*display: none;*/
}

.wegLeft2 {
  transform: translateX(20%);
  opacity: 0;
  /*content-visibility: hidden;*/
  /*display: none;*/
}

.wegLeft3 {
  transform: translateX(20%);
  opacity: 0;
  content-visibility: hidden;
  display: none;
}

.fadeLeft2 {
  transform: translateX(-20%) !important;
  opacity: 0;
  /*content-visibility: hidden;*/
  /*display: none;*/
}



.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




.lds-dual-ring2 {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.lds-dual-ring2:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring2 1.2s linear infinite;
}
@keyframes lds-dual-ring2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




#top-container {
  list-style: none;
}

#top-container:not(.asdf) li:before {
  content: "+";
  margin-right: 12px;
}

.MuiFormHelperText-root {
  font-size: 10px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.scrollToTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}


.loginSvg.yellow .cls-1 {
  fill: #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.loginSvg.blue .cls-1 {
  fill: #00374e;
  transition: all 0.2s ease-in-out 0s;
}


.loginSvgWhite.yellow .cls-1 {
  fill: #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.loginSvgWhite.blue .cls-1 {
  fill: white;
  transition: all 0.2s ease-in-out 0s;
}


.Test.yellow {
  fill: white;
  border: 2px solid white;
  transition: all 0.2s ease-in-out 0s;
}

.Test.blue {
  fill: #C7D300;
  border: 2px solid  #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.TestF.yellow {
  fill: #00374e;
  border: 2px solid #00374e;
  transition: all 0.2s ease-in-out 0s;
}

.TestF.blue {
  fill: #C7D300;
  border: 2px solid  #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.Toastify__toast {
  max-width: 95%;
  margin: 10px auto 0 !important;
}

.Toastify__toast-body {
  padding: 6px 25px 6px 10px !important;
}

/*.menuItem.arbeitnehmer {*/
/*  text-align: left;*/
/*  margin-left: 15px;*/
/*}*/

.Arbeitnehmer {
  color: #00374e;
}

.Arbeitgeber {
  color: white;
}

.menuItemMain {
  /*transition: all 0.2s ease-in-out 0s;*/
  cursor: pointer;
  /*padding: 7px 0 4px 15px;*/
  margin: 3px 0 0 0;
}

.menuItem {
  /*transition: all 0.2s ease-in-out 0s;*/
  position: relative;
  width: max-content;
  padding-right: 8px;
}

.hover {

}

.active {
  background: rgb(138, 138, 138, 0.17);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.count {
  position: absolute;
  top: -15px;
  right: -17px;
  width: fit-content;
  height: 10px;
  min-width: 10px;
  padding: 4px 4px 4px 4px;
  color: #00374e;
  background: #C7D300;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*box-shadow: 0 0 0 1px rgb(199 211 0 / 20%), 0 0 0 2px rgb(199 211 0 / 19%), 0 0 0 3px rgb(199 211 0 / 18%);*/
  filter: drop-shadow(0 0 1px #C7D300);
  margin: 1em 0;

}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column-p {
  padding: 5px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-vert {
  display: flex;
  align-items: center;
}

.center-hor {
  display: flex;
  justify-content: center;
}

.color-blue {
  color: #00374e;
}
.color-yellow {
  color: #C7D300 !important;
}


#hakenSuchen .cls-1 {
  fill: white;
}


#infoIcon,
#infoIcon:hover {
  color: #C7D300;
}

#infoIcon-white,
#infoIcon-white:hover {
  color: white;
}

.MuiTooltip-popper {
  inset: 0 auto auto 40px !important;
}

.MuiTooltip-tooltip {
  max-width: 300px !important;
}

#erroricon,
#erroricon:hover {
  color: #FF0000;
}

.MuiTooltip-tooltip {
  padding: 6px 8px 4px !important;
  margin-top: 10px !important;
  /*text-align: center !important;*/
}

#übersicht .heart.cls-1 {
  fill: white !important;
}

.testMode:before {
  content: "\2022";
  margin-right: 5px;
}

.borderWithMarginStart:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #00374e;
  width: calc(100% - 240px);
  top: 0;
  left: 120px;
}

.borderWithMargin:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #aaa;
  width: calc(100% - 240px);
  top: 0;
  left: 120px;
}

.logoJobsucheFavorite .cls-1 {
  fill: rgba(0, 55, 78, 0.5);
}


#blauweiß .st0 {
  fill: #00374e;
}



.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 0 0 0 10px;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #00374e;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
  //transform: translateY(-6px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.15s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.15s;
}

#detailsBlock {
  text-align: center;
}

#detailsBlock summary {
  color: #C7D300;
  font-family: VagRoundedBold
}

#detailsBlock details {
  margin: 0px 19px;
  text-align: left;
}

#detailsBlock ul {
  margin: 0;
  padding-inline-start: 13px;
  color: white;
  position: relative;
}

#detailsBlock li {
  list-style-type: none;
}

#detailsBlock details > summary {
  display: inline-block;
}

#detailsBlock details[open] > summary {
  display: inline-block;
}

#detailsBlock details > summary::before {
  content: '+';
  display: inline-block;
  font-size: 16px;
  line-height: 10px;
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

#detailsBlock details[open] > summary::before {
  content: '';
  display: inline-block;
  font-size: 20px;
  line-height: 0;
  width: 8px;
  height: 0;
  border-bottom: 2px solid;
  margin-bottom: 4px;
  margin-right: 3px;
  margin-left: 2px;
}

#detailsBlock details[open] > summary #text {
  padding-top: 1px;
}

#detailsBlock .number {
  position: absolute;
  top: 0;
  font-size: 9px;
  margin-left: 2px;
}

#detailsBlock details ul {
  transition: all .2s ease-in-out;
}



@keyframes sweep {
  0%    {height: 0;}
  100%  {height: 100px;}
}

.text-input-label-blue label,
.text-input-label-blue input,
.label-blue {
  color: #00374e !important;
}

.label-black label {
  color: black !important;
}



/*.text-input-label-blue label.MuiFormLabel-filled  {*/
/*  font-size: 14px !important;*/
/*}*/




ul#oiuz {
  display: flex;
}

ul#oiuz,
#oiuz li {
  padding-left: 20px;
}
#oiuz li span {
  position: relative;
  left: -22px;
}


#stepper .MuiStepLabel-root .MuiSvgIcon-root,
#stepper .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
  color: #C7D300;
}

#stepper .MuiStepLabel-root .MuiSvgIcon-root {
  height: 40px;
  width: 40px;
}

#stepper .MuiSvgIcon-root .MuiStepIcon-text {
  font-family: VagRoundedBold, serif;
}

#stepper .MuiStepConnector-line {
  border-color: #C7D300;
  border-top-style: dashed;
  border-top-width: 2px;
}

#stepperMatchkorb .MuiStepLabel-root .MuiSvgIcon-root,
#stepperMatchkorb .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
  color: #C7D300;
}

#stepperMatchkorb .MuiStepLabel-root .MuiSvgIcon-root {
  height: 40px;
  width: 40px;
}

#stepperMatchkorb .MuiSvgIcon-root .MuiStepIcon-text {
  font-family: VagRoundedBold, serif;
}

#stepperMatchkorb .MuiStepConnector-line {
  border-color: #C7D300;
  border-top-style: dashed;
  border-top-width: 2px;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal {
  position: relative;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal:nth-child(1) .MuiStepLabel-labelContainer {
  position: absolute;
  right: 0;
  top: 42px;
  width: unset;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal:nth-child(3) .MuiStepLabel-labelContainer {
  position: absolute;
  right: 5px;
  top: 42px;
  width: unset;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal:nth-child(5) .MuiStepLabel-labelContainer {
  position: absolute;
  right: -7px;
  top: 42px;
  width: unset;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label {
  color: white;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal.activeStep .MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-family: VagRoundedBold;
}




#stepper2 .MuiStepLabel-root .MuiSvgIcon-root,
#stepper2 .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
  color: #C7D300;
}

#stepper2 #stepper .MuiSvgIcon-root {
  height: 40px;
  width: 40px;
}

#step3 .MuiTypography-root {
  margin-top: 2px;
}

#stepper2 .MuiButton-root {
  color: white;
}

#stepper2 .MuiSvgIcon-root .MuiStepIcon-text {
  font-family: VagRoundedBold, serif;
  fill: #00374e;
}

#stepper2 .MuiStepConnector-line {
  border-color: #C7D300;
  border-top-style: dashed;
  border-top-width: 2px;
}

#stepper2 .MuiStepLabel-label {
  color: white;
}

#stepper2 .MuiStepLabel-label.Mui-active {
  font-family: VagRoundedBold;
}


#testMatchkorb p {
  margin: 0;
}


#einkaufswagen .cls-1 {
  fill: white;
}

#einkaufswagen {
  background: #C7D300;
}

#matchkorb .cls-1 {
  fill: #C7D300 !important;
}

#matchkorb .cls-2 {
  fill: transparent !important;
}

#stepper2 .MuiStepLabel-root {
  position: relative;
}

#stepper2 .MuiStepLabel-labelContainer  {
  position: absolute;
  top: 45px;
  left: -10px;
}

#stepper2 .MuiStep-horizontal:nth-child(1) .MuiStepLabel-labelContainer {
  left: -14px;
}
#stepper2 .MuiStep-horizontal:nth-child(3) .MuiStepLabel-labelContainer {
  left: -10px;
}
#stepper2 .MuiStep-horizontal:nth-child(5) .MuiStepLabel-labelContainer {
  left: -8px;
}

/*.MuiFormHelperText-contained {*/
/*  min-height: 17px;*/
/*}*/





.border-blue .MuiOutlinedInput-root fieldset {
  border-color: #00374e !important;
}

#step3 .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  fill: white;
}

/*#step3 .MuiTouchRipple-rippleVisible {*/
/*  width: 60% !important;*/
/*  height: 60% !important;*/
/*}*/

.dingLabel .MuiFormControlLabel-label {
  font-size: 14px;
}


#step3 .MuiStepper-root svg:nth-child(2),
#step3 .MuiRadio-root svg:nth-child(2) {
  fill: #C7D300 !important;
}

.testClassSelect .MuiOutlinedInput-notchedOutline {
  border-radius: 25px;
  border: 2px solid #C7D300 !important;
}

.testClassSelect .MuiOutlinedInput-input {
  padding: 8.6px !important;
}

.testClassSelect label {
  top: -8px !important;
  color: #00374e !important;
  left: 6px;
  /*font-size: 12px;*/
}

.testClassSelect .MuiOutlinedInput-input {
  padding-right: 0 !important;
  text-align: left;
  padding-left: 18px !important;
  font-size: 12px;
  color: black;
}

.registrationMenu label {
  font-size: 12px !important;
}

.testClassSelect:not(.blue):not(.jobTypes) label:not(.whiteLabel) {
  top: -4px !important;
  color: black !important;
}

.testClassSelect label.whiteLabel {
  color: white !important;
}

/*.testClassSelect label:not(.Mui-focused) {*/
/*  top: -4px !important;*/
/*}*/

.testClassSelect:not(.blue) label {
  top: -4px !important;
}

.testClassSelect.blue label {
  top: -6px !important;
}

.testClassSelect.white label {
  color: white !important;
}

.MuiFormHelperText-contained:not(.Mui-error) {
  color: white !important;
}

.greyLabel .MuiFormHelperText-contained:not(.Mui-error) {
  color: rgba(0, 0, 0, 0.6) !important;
}

#jobAdvertisementTitel-helper-text {
  color: rgb(0, 55, 78) !important;
}


/*.testClassSelect label.MuiFormLabel-filled,*/
/*.testClassSelect label.Mui-focused {*/
/*  !*color: white !important;*!*/
/*  font-size: 12px !important;*/
/*}*/
.testClassSelect label {
  font-size: 12px !important;
}

.registerTest .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  background: white !important;
  border-radius: 25px;
}

.loginTest .MuiTextField-root label.MuiFormLabel-filled,
.registerTest .MuiTextField-root label.MuiFormLabel-filled {
  color: white !important;
}

.teeeest div {
  width: calc(100% - 5px);
}

#editor {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

#editor .rdw-editor-wrapper {
  border: 1px solid #ddd;
  padding: 5px;
  /*min-height: 400px;*/
}

.ck-editor__editable_inline,
.DraftEditor-root {
  /*min-height: 400px;*/
}

.fr-second-toolbar a {
  display: none !important;
}

.imageUpload .image-item {
  display: flex;
  margin: 10px 0;
}
.imageUpload .image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.imageUpload button {
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
}

.imageUpload .image-item__btn-wrapper {
  width: 100%;
}

#unternehmensprofil .MuiDataGrid-columnSeparator {
  display: none !important;
}

#unternehmensprofil .image-item,
#unternehmensprofil img {
  width: 162px;
  height: 162px;
}

#unternehmensprofil .image-item__btn-wrapper {
  margin-left: 10px;
}

#unternehmensprofil .MuiDataGrid-cell[data-field="adresse"] {
  padding-top: 5px;
  text-align: left;
  max-height: fit-content;
  display: blocK;
}

#unternehmensprofil .MuiDataGrid-cell[data-field="rechnungsadresse"] {
  /*padding-top: 5px;*/
  text-align: center;
  /*max-height: fit-content;*/
  /*display: blocK;*/
}

#unternehmensprofil .MuiCheckbox-root {
  margin: auto;
}

.testClassSelect.whiteLabel,
.testClassSelect.whiteLabel svg,
.testClassSelect.whiteLabel div,
.whiteLabel {
  color: white !important;
}

.testClassSelect.greyLabel,
.testClassSelect.greyLabel svg,
.testClassSelect.greyLabel div,
.greyLabel {
  color: grey !important;
}

.testClassSelect {
  /*margin-top: 25px !important;*/
}

.text-input-label-white svg,
.text-input-label-white .MuiChip-label {
  color: white !important;
}

.text-input-label-white .MuiChip-filledDefault {
  border: 1px solid #C7D300;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.multiLineInputColor .MuiOutlinedInput-input {
  color: white !important;
}

.borderWhite .MuiOutlinedInput-root fieldset,
.borderWhite label {
  color: white !important;
  border-color: white !important;
}

.dateTimeModalPicker {
  margin-top: 10px !important;
}


.borderWhite fieldset,
.borderWhite label,
.borderWhite input,
.borderYellow input {
  color: white !important;
  border-color: white !important;
}

.borderWhite fieldset,
.borderYellow fieldset {
  border-radius: 25px;
  border-width: 2px !important;
}

.borderYellow fieldset,
.borderYellow label {
  color: #C7D300 !important;
  border-color: #C7D300 !important;
}

.dateTimeModalPicker {
  width: 100%;
}

.dateTimeModalPicker .MuiOutlinedInput-input {
  padding: 18.5px 20px;
}

.dateTimeModalPicker input {
  font-size: 14px !important;
}

.dateTimeModalPicker .MuiFormHelperText-root {
  /*display: none;*/
}

.MuiPickersToolbar-toolbar {
  background-color: #C7D300 !important;
}

.MuiDialogContent-root h6,
.MuiDialogContent-root h4,
.MuiDialogContent-root button,
.MuiDialogContent-root span,
.MuiDialogContent-root div,
.MuiDialogActions-root span {
  color: #00374e !important;
}

.AFFFF:has(label.Mui-focused) {
  margin-top: 20px !important;
}



.msg2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.msg2 p {
  font-size: 14px;
}

.msg2 {
  width: 280px;
  height: 184px;
  padding: 6px 10px;
  margin: 10px;
  border-radius: 25px;
  position: relative;
  background: rgba(221, 221, 221, .3);
  font-size: 12px;
}

.msg2:after {
  content: "";
  position: absolute;
  border: 30px solid transparent;
  border-top: 30px solid rgba(221,221,221, .3);
  border-right: none;
  bottom: -60px;
  right: 22px;
}

.MuiRadio-root {
  padding: 0 !important;
}

.MuiFormControl-root.testC {
  margin-left: 10px !important;
  padding: 8px 0 5px;
}

.MuiFormControl-root.testC .MuiTypography-root {
  font-size: 12px;
  margin-left: 5px;
}

#step4 .MuiTypography-root {
  margin-top: 2px;
}

#step4 .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  fill: #ddd;
}

#step4 svg:nth-child(2):not(.not-yellow) {
  fill: #C7D300 !important;
}

#step5 .MuiTypography-root {
}

#step5 .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  fill: #ddd;
}

#step5 svg:nth-child(2):not(.not-yellow) {
  fill: #C7D300 !important;
}


.not-yellow .st0 {
  fill: #00374e !important;
}

.not-yellow-white .st0 {
  fill: white !important;
}

.icon-button:hover {
  background-color: #C7D300 !important;
}

.normal-button:hover {
  background-color: #C7D300 !important;
}

body.react-confirm-alert-body-element {
  overflow: unset !important;
}


.MuiChip-root {
  height: fit-content !important;
  text-align: left !important;
}


.MuiChip-label {
  overflow: unset !important;
  text-overflow: unset !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  white-space: pre-line !important;
  max-width: 100% !important;
  height: fit-content !important;
}


#stepper .MuiAutocomplete-clearIndicator {
  display: none;
}

.MuiSlider-root {
  padding: 5px 0 !important;
}

.ASDF .MuiInputBase-root {
  max-height: 41.13px;
}

.ASDF .MuiOutlinedInput-root {
  padding: 0 !important;
}

.ASDF input {
  max-width: 73%;
}

.bg-transparent div div button {
  color: #C7D300 !important;
}

.bg-transparent label,
.bg-transparent div,
.bg-transparent input {
  background: transparent !important;
  color: white !important;
}

.customBullet {
  list-style: none;
  position: relative;
  line-height: 1.5em;
  font-family: VagRoundedBold;
  font-size: 12px;
}

.customBullet li:not(.customClearIcon):before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1em;
  content: '\25CF';
  font-size: 12px;
  margin: 0 1em 0 0;    /* any design */
  left: -0.2em;
}

.customBullet li:not(.customClearIcon) {
  padding-left: 1em;
}

.customBullet li.customClearIcon:before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1em;
  content: '\2717';
  font-size: 12px;
  margin: 0 1em 0 0;    /* any design */
  left: -0.2em;
}

.customBullet li.customClearIcon {
  padding-left: 1em;
  color: #c7c4c482;
}



/* width */
.newAddressDiv {
  transition: background-color;
}

.overscroll-none {
  overscroll-behavior: contain;
}
.newAddressDiv ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.newAddressDiv ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
.newAddressDiv ::-webkit-scrollbar-thumb {
  background-color: #C7D300;
}

/* Handle on hover */
.newAddressDiv ::-webkit-scrollbar-thumb:hover {
  background-color: #adb800;
}

.newAddressDiv .testC .MuiFormControlLabel-root {
  vertical-align: top;
  align-items: flex-start;
  margin-right: 0;
}

.newAddressDiv .MuiTypography-root {
  width: 100%;
}
.emailRadio .MuiTypography-root {
  margin-left: 10px;
}

.defaultRadio .MuiTypography-root {
  margin-left: 10px;
  font-size: 12px;
}

.buttonLinkYellow:hover {
  color: #00374e !important;
}

.buttonLinkBlue:hover {
  color: #adb800 !important;
}

/*.toggle-switch input {*/
/*  display: none;*/
/*}*/

/*.toggle-switch {*/
/*  position: relative;*/
/*  !*width: 75px;*!*/
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*  -webkit-user-select: none;*/
/*  -moz-user-select: none;*/
/*  -ms-user-select: none;*/
/*  text-align: left;*/
/*}*/
/*.toggle-switch-checkbox {*/
/*  display: none;*/
/*}*/
/*.toggle-switch-label {*/
/*  display: block;*/
/*  overflow: hidden;*/
/*  cursor: pointer;*/
/*  border: 0 solid #bbb;*/
/*  border-radius: 20px;*/
/*  margin: 0;*/
/*}*/
/*.toggle-switch-inner {*/
/*  display: block;*/
/*  width: 200%;*/
/*  margin-left: -100%;*/
/*  transition: margin 0.3s ease-in 0s;*/
/*}*/
/*.toggle-switch-inner:before, .toggle-switch-inner:after {*/
/*  display: block;*/
/*  float: left;*/
/*  width: 50%;*/
/*  height: 34px;*/
/*  padding: 0;*/
/*  line-height: 34px;*/
/*  font-size: 14px;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*  box-sizing: border-box;*/
/*}*/
/*.toggle-switch-inner:before {*/
/*  content: "Matches";*/
/*  text-transform: uppercase;*/
/*  padding-left: 10px;*/
/*  background-color: #f90;*/
/*  color: #fff;*/
/*}*/
/*.toggle-switch-disabled {*/
/*  background-color: #ddd;*/
/*  cursor: not-allowed;*/
/*}*/
/*.toggle-switch-disabled:before {*/
/*  background-color: #ddd;*/
/*  cursor: not-allowed;*/
/*}*/
/*.toggle-switch-inner:after {*/
/*  content: "Anonymes Profil";*/
/*  text-transform: uppercase;*/
/*  padding-right: 10px;*/
/*  background-color: #bbb;*/
/*  color: #fff;*/
/*  text-align: right;*/
/*}*/
/*.toggle-switch-switch {*/
/*  display: block;*/
/*  width: 24px;*/
/*  margin: 5px;*/
/*  background: #fff;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: unset;*/
/*  border: 0 solid #bbb;*/
/*  border-radius: 20px;*/
/*  transition: all 0.3s ease-in 0s;*/
/*}*/
/*.toggle-switch-checkbox,*/
/*.toggle-switch-checkbox-checked,*/
/*.toggle-switch-label,*/
/*.toggle-switch {*/
/*  transition: all 0.3s ease-in-out;*/
/*}*/

/*.toggle-switch-checkbox-checked + .toggle-switch-label .toggle-switch-inner {*/
/*  margin-left: 0;*/
/*}*/
/*.toggle-switch-checkbox-checked + .toggle-switch-label .toggle-switch-switch {*/
/*  right: 0;*/
/*  left: unset;*/
/*}*/

.toggle-switch input {
  display: none;
}


.toggle-switch {
  /*background: #00374e;*/
  position: relative;
  margin-right: 10px;
  width: 100%;
  max-width: 350px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  height: 34px;
  /*overflow: hidden;*/
  cursor: pointer;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-label:focus {
  outline: none;
}
.toggle-switch-label:focus > span {
  /*box-shadow: 0 0 2px 5px red;*/
}
.toggle-switch-label > span:focus {
  outline: none;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: 0;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
  display: block;
  float: left;
  /*width: 25%;*/
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
  /*transition: all 0.01s ease-in-out;*/
  width: calc(50% + 8px);
}

.toggle-switch-inner:before {
  content: attr(data-yes);
  padding-left: 20px;
  /*background-color: #00374e;*/
  color: white;
  font-size: 16px;
  line-height: 36px;
  box-shadow: 0 0 0 3px #00374e;
  /*clip-path: inset(-3px 10px -3px -3px);*/
  margin-left: 1px;
  position: absolute;
}

.toggle-switch-checkbox + .toggle-switch-label .toggle-switch-inner:before {
  font-size: 16px;
  /*width: 27.5%;*/
  border-radius: 20px 20px 20px 20px;
  zoom: 1.1;
  margin-top: -2px;
  /*margin-left: 5px;*/
  background: #00374e;
  z-index: 2;
}

.toggle-switch-not-checked {
  color: #00374e;
}

.toggle-switch-checked {
  zoom: 1.1;
  color: white;
}

.toggle-switch-checkbox + .toggle-switch-label .toggle-switch-inner:after {
  font-size: 16px;
  /*width: 22.5%;*/
  /*border-radius: 14px 20px 20px 14px;*/
  border-radius: 14px 20px 20px 14px;
}

.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  padding-right: 20px;
  background-color: white;
  color: #00374e;
  text-align: right;
  font-size: 16px;
  line-height: 36px;
  box-shadow: 0 0 0 3px #00374e;
  /*clip-path: inset(-3px -3px -3px 10px);*/
  margin-right: 1px;
  position: absolute;
  right: 0;
}
.toggle-switch-checkbox-checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox-checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.toggle-switch-checkbox-checked + .toggle-switch-label .toggle-switch-inner:before {
  background-color: white;
  color: #00374e;
  /*width: 22.5%;*/
  border-radius: 20px 14px 14px 20px;
}

.toggle-switch-checkbox-checked + .toggle-switch-label .toggle-switch-inner:after {
  background-color: #00374e;
  color: white;
  font-size: 16px;
  /*width: 27.5%;*/
  border-radius: 20px 20px 20px 20px;
  zoom: 1.1;
  margin-top: -3px;
  /*margin-right: 5px;*/
  z-index: 2;
}

.toggle-switch.small-switch {
  width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
  content: "";
  height: 20px;
  line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
  width: 16px;
  right: 20px;
  margin: 2px;
}
@media screen and (max-width: 991px) {
  .toggle-switch {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 767px) {
  .toggle-switch {
    transform: scale(0.825);
  }
}
@media screen and (max-width: 575px) {
  .toggle-switch {
    transform: scale(0.75);
  }
}


.MuiFormControl-root.testD {
  margin-left: 10px !important;
  padding: 8px 0 5px;
}

.MuiFormControl-root.testD .MuiTypography-root {
  font-size: 14px;
  margin-left: 5px;
  font-family: VagRoundedBold;
}

.autocompleteSearchJobsuche .MuiAutocomplete-endAdornment {
  right: 40px !important;
}



@media screen and (max-width: 530px) {
  .menuAppBarType {
    font-size: 12px;
  }
}


.oldLinkStyle {
  color: #00374a;
  text-decoration: unset;
  transition: all 0.2s ease-in-out;
  font-family: VagRoundedRegular;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #c7d300 !important;
  opacity: 1 !important;
}

.MuiSwitch-thumb {
  color: white !important;
}

.oldLinkStyle:hover {
  color: #adb800;
}

.hideCursor input {
  caret-color: transparent !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.topAnswer {
  line-height: 38px;
  margin-bottom: 5px;
}

.answer {
  line-height: 18px;
  padding: 10px 20px 6px 20px;
  background: #eee;
  border-radius: 25px;
  margin: 5px 5px 5px 0;
}

.rightAnswer {
  background: rgb(199, 211, 0);
}

.Denker {
  background-image: url("images/svg/Illustration_TTVerlag_Denker_Kontur.svg");
  background-repeat: no-repeat;
  background-position: top right 7%;
  background-size: contain;
}

#mouse-over-popover .MuiPaper-root{
  border-radius: 25px !important;
  background: rgba(0, 55, 78, 0.95) !important;
  color: white !important;

}

#mouse-over-popover-filter .MuiPaper-root{
  background: rgba(0, 55, 78, 1) !important;
  color: white !important;
  border-radius: 25px !important;
}

.Diplomat {
  background-image: url("images/svg/Illustration_TTVerlag_Diplomat_Kontur.svg");
  background-repeat: no-repeat;
  background-position: top right 7%;
  background-size: contain;
}

.Entdecker {
  background-image: url("images/svg/Illustration_TTVerlag_Entdecker_Kontur.svg");
  background-repeat: no-repeat;
  background-position: top right 7%;
  background-size: contain;
}

.Helfer {
  background-image: url("images/svg/Illustration_TTVerlag_Helfer_Kontur.svg");
  background-repeat: no-repeat;
  background-position: top right 7%;
  background-size: contain;
}


.editAddress .MuiAutocomplete-endAdornment,
.editContact .MuiAutocomplete-endAdornment {
  display: none;
}

.eyeIcon .st0 {
  fill: #ddd;
}

.public-DraftEditor-content {
  min-height: 400px;
  padding: 0 3px;
}

.linkToJob:hover {
  text-decoration: underline !important;
}

.descriptionAnonymSwiitchen {
  font-family: VagRoundedBold;
  color: #00374e;
  line-height: 16px;
}

.descriptionAnonymSwiitchen:focus {
  outline: none !important;
}

.descriptionAnonymSwiitchen::-webkit-input-placeholder {
  opacity: 0.5; /*Change the opacity between 0 and 1*/
}

.testClassSelect.jobTypes label {
  top: 4px !important;
}

.testClassSelect.jobTypes .MuiOutlinedInput-root {
  height: 55px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.css-1in441m {
  float: unset;
  overflow: hidden;
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.css-1in441m>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}


#mWDSwitch {
  margin-left: 15px;
  padding-right: 10px;
  background: #eee;
  border-radius: 50px;
}


.count2 {
  line-height: 11px;
  padding: 4px 7px;
  background: rgb(173, 184, 0);
  border-radius: 50px;
  font-size: 10px;
  display: inline-block;
  height: 9px;
}


.areaCodeOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.afterRegister .filled fieldset {
  border: 2px solid #C7D300 !important;
}

/* Chrome, Safari, Edge, Opera */
.afterRegister input::-webkit-outer-spin-button,
.afterRegister input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.afterRegister input[type=number] {
  -moz-appearance: textfield;
}

.useMailOnly span {
  font-size: 12px;
  color: white;
  font-family: VagRoundedBold;
}

label.useMailOnly {
  margin: 0;
}

.useMailOnly > span > span {
  margin-right: 5px;
}

.useMailOnly .MuiRadio-root {
  color: white;
}

.useMailOnly > span.Mui-checked > span {
  color: #C7D300;
}





body {
  --ReactInputVerificationCode-itemWidth: 2rem;
  --ReactInputVerificationCode-itemHeight: 2rem;
  --ReactInputVerificationCode-itemSpacing: 0.5rem;
}

.ReactInputVerificationCode__container {
  background: white !important;
  border-radius: 5px;
  color: #00374a;
}

.ReactInputVerificationCode__item {
  font-size: 25px !important;
  background: none !important;
  box-shadow: unset !important;
  border: 2px solid transparent !important;
  line-height: 36px !important;
}



.ReactInputVerificationCode__item.is-filled {
  /*border: 2px solid #C7D300 !important;*/
}

.ReactInputVerificationCode__item.is-active {
  /*border: 2px solid #C7D300 !important;*/
}


.disable-selecting {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}


#subAutocomplete legend {
  max-width: 91% !important;
  width: 220px !important;
}

#subAutocomplete legend span {
  padding-right: 0 !important;
}

#subAutocomplete .MuiAutocomplete-inputRoot {
  align-items: baseline;
  min-height: 110px;
  padding-top: 15px;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.display {
  /*border-right: 1px solid rgba(0, 0, 0, 0.2);*/
  color: #00374e;
  width: 40px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  position: relative;
}
.display:last-child {
  border-right: none;
}

.wrap {
  background: white;
  display: inline-block;
  position: relative;
  display: flex;
  border-radius: 5px;
}

.code-input {
  position: absolute;
  border: none;
  font-size: 25px;
  text-align: center;
  background-color: transparent;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
.wrap input::-webkit-outer-spin-button,
.wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.wrap input[type=number] {
  -moz-appearance: textfield;
}

.questions-logo-small{
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 4;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.arrow-back-button-anonym-swiitchen-desktop{
  height: 40px;
  width: 40px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 4;
  color: white;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.arrow-back-button-anonym-swiitchen-mobile{
  height: 40px;
  width: 40px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 4;
  color: white;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.arrow-back-button-login-desktop{
  height: 40px;
  width: 40px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 4;
  color: white;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.arrow-back-button-login-mobile{
  height: 40px;
  width: 40px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 4;
  color: white;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.questions-logo-small:hover:active,
.questions-logo-small:hover,
.arrow-back-button:hover{
  transform: scale(1.2);
}

.question-logo-big{
  height: 35px;
  aspect-ratio: 6.82;
  position: absolute;
  left: calc(50% - 119px);
  top: 50px;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.login-and-registration-logo-big{
  height: 35px;
  aspect-ratio: 6.82;
  cursor: pointer;
  pointer-events: all;
  transform: scale(1.0);
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.question-logo-big:hover,
.login-and-registration-logo-big:hover{
  transform: scale(1.2);
}
