@font-face {
  font-family: "BarlowBlack";
  src: local("BarlowBlack"),
  url("./fonts/Barlow/Barlow-Black.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "BarlowBold";
  src: local("BarlowBold"),
  url("./fonts/Barlow/Barlow-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "BarlowRegular";
  src: local("BarlowRegular"),
  url("./fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "VagRoundedBold";
  src: local("VagRoundedBold"),
  url("./fonts/VAG Rounded/VAG_Rundschrift.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "VagRoundedRegular";
  src: local("VagRoundedRegular"),
  url("./fonts/VAG Rounded/VAG_Rounded_Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Sofia Sans';
  src: url('./fonts/SofiaSans/SofiaSans-VariableFont_wght.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  font-family: 'VagRoundedRegular', 'Arial', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
