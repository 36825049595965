
.login-registration-container {
    background-color: #00374e !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center;
    padding: 50px !important;
    flex-grow: 1;
}

.login-heading {
    font-family: 'VagRoundedBold', serif;
    color: white;
    padding-bottom: 10px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 280px;
}

.input {
    transition: all 0.2s ease;
    width: 100%;
}

.submit-button {
    font-size: 12px;
    font-family: VagRoundedBold, serif;
    background-color: #C7D300;
    cursor: pointer;
    margin: auto;
    text-align: center;
    padding: 10px 20px;
    color: #00374e;
    border: 2px solid #00374e;
    width: 100%;
}

.submit-button:hover {
    background-color: #00374e;
    color: #C7D300;
    border: 2px solid #C7D300;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.submit-info {
    color: white;
    font-size: 14px;
    text-align: left;
    margin-left: 18px;
}

.forgot-password {
    color: white;
    font-size: 14px;
    text-align: left;
    margin-left: 18px;
    cursor: pointer;
}

.forgot-password:hover {
    color: #C7D300;
}

.switch-login-registration-button {
    font-size: 10px !important;
}

.select-type-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.select-type-label {
    font-family: 'VagRoundedBold', serif;
    text-align: left;
    color: white;
    margin-left: 18px;
    top: -8px;
    font-size: 10px !important;
    line-height: 1.4375em !important;
    font-weight: 100;
}

.select-type-input {
    border: 2px solid #C7D300;
    border-radius: 50px;
    padding: 10px 18px;
}

.forgot-password-modal {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    min-width: 200px;
    max-height: 70%;
    overflow: auto;
    background-color: rgba(218, 227, 243, 0.95);
    border: 2px solid transparent !important;
    border-radius: 20px;
    padding: 5px 16px 20px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
    z-index: 4;
    line-height: 13px;
}

.login-parent {
    height: 100vh;
    width: 100vw;
    background-color: #00374e;
    display: flex;
    align-items: center;
    flex-direction: column;
}
