.mainApp {
    margin: 0px;
    padding: 0px;
}

.mainApp a {
    text-decoration: none;
}

.mainApp button {
    cursor: pointer;
}

.mainApp input[type="search"] {
    outline: none;
}

.mainApp button, .mainApp input[type="text"] {
    /*border: none;*/
    /*outline: none;*/
}

/* ----------------- */
/* MAIN APP ELEMENTS */
/* ----------------- */

.mainApp .leftPanel {
    position: relative;
    display: inline-block;
    left: 0px;
    width: 320px;
    /*width: 400px;*/
    height: calc(100vh - 64px);
    float: left;
    background: #FFF;
    /*border-right: 1px solid #DDD;*/
}

.mainApp header {
    width: 100%;
    height: 110px;
    background: #FFF;
}

.mainApp .trigger {
    float: left;
    width: 32px;
    height: 32px;
    margin: 20px 15px;
    margin-bottom: 0px;
    color: #BBB;
    cursor: pointer;
    opacity: 0.6;
    background: none;
}

.mainApp .trigger:hover {
    opacity: 1;
}

.mainApp .trigger > svg {
    width: 24px;
    height: 24px;
    fill: #888;
}

.mainApp .searchChats {
    width: calc(100% - 85px);
    height: 40px;
    background: #EEE;
    float: left;
    padding: 8px;
    margin-top: 14px;
    border: 2px solid white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}

.mainApp .searchChats:focus {
    color: #444;
    border: 2px solid #419fd9;
    background: rgb(243, 243, 243);
}

.mainApp .chats {
    width: 100%;
    height: calc(100vh - 64px);
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 5px;
}

.mainApp .chatButton {
    float: left;
    width: calc(100% - 20px);
    min-height: 49px;
    margin: 2px 10px;
    color: #555;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    overflow: hidden;
}

.mainApp .chatButton:not(.activeChat) {
    background: rgba(221, 221, 221, 0.1);
}

.mainApp .chatButton:hover {
    color: #555;
    background: rgba(221, 221, 221, 0.3);
}

.mainApp .activeChat {
    color: #FFF;
    background: rgba(199, 211, 0, 0.1);
}

.mainApp .activeChat:hover {
    background: rgba(199, 211, 0, 0.3);
}

.mainApp .chatInfo {
    float: left;
    width: 100%;
    margin: 2px 10px;
    display: flex;
}

.mainApp .chatInfo p {
    float: left;
    font-family: VagRoundedBold, serif;
}

.mainApp .chatInfo .image {
    width: 55px;
    height: 55px;
    background: #DDD;
    background-size: cover;
    border-radius: 100%;
    margin: 13px;
}

.mainApp .chatInfo .name {
    float: left;
    font-size: 13px;
    font-weight: 600;
    color: #00374e;
    /*margin-left: 80px;*/
    margin-top: 0;
    margin-bottom: 0;
}

.mainApp .chatButton .chatInfo .name {

}

.mainApp .chatButton.activeChat .chatInfo .name {

}

.mainApp .chatInfo .message {
    float: left;
    clear: left;
    font-size: 14px;
}

.mainApp .chatButton .chatInfo .message {
    font-family: VagRoundedRegular, serif;
    color: grey;
    margin: 0;
}

.mainApp .chatButton:not(.activeChat).unread .chatInfo .message {
    font-family: VagRoundedBold, serif;
    color: #C7D300;
}

.mainApp .status {
    color: black;
    height: 100%;
    width: 100%;
    position: relative;
    float: right;
}

.mainApp .status .fixed {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: none;
}


.mainApp .status .count, .mainApp .count, #top-container .count {
    position: absolute;
    top: 25px;
    right: 0px;
    width: 20px;
    height: 20px;
    padding: 4px 4px 4px 4px;
    color: #00374e;
    background: #C7D300;
    border-radius: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*box-shadow: 0 0 0 1px rgb(199 211 0 / 20%), 0 0 0 2px rgb(199 211 0 / 19%), 0 0 0 3px rgb(199 211 0 / 18%);*/
    filter: drop-shadow(0 0 1px #C7D300);
    margin: 1em;

}

.mainApp .status .date {
    position: absolute;
    right: 15px;
    top: 0;
    margin: 0;
    font-size: 12px;
}

.mainApp .chatButton.activeChat:not(.unread) .status .date,
.mainApp .chatButton:not(.unread) .status .date {
    font-weight: normal;
    font-family: VagRoundedRegular, serif;
}

.mainApp .status .read {
    position: absolute;
    right: 53px;
    top: 6px;
}

.mainApp .rightPanel {
    float: left;
    width: calc(100% - 321px);
    /*background: white;*/
    /*padding-top: 6px;*/
}

.mainApp .rightPanel .topBar {
    position: relative;
    width: 100%;
    height: 50px;
    background: #FFF;
}

.mainApp .leftSide {
    display: inline-block;
    clear: none;
    float: left;
}

.mainApp .leftSide .chatName, #top-container .chatName {
    float: left;
    font-family: VagRoundedBold, serif;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 0;
    color: #00374e;
    font-weight: 600;
    cursor: default;
}

.mainApp .chatName > span {
    font-size: 12px;
    font-weight: 500;
    color: #BBB;
    margin-left: 10px;
}

.mainApp .leftSide .chatStatus {
    float: left;
    clear: left;
    margin-left: 30px;  
    margin-top: 5px;
    margin-bottom: 5px;
    color: #aaa;
    font-weight: 300;
    cursor: default;
}

.mainApp .rightSide {
    display: inline-block;
    clear: none;
    float: right;
}

.mainApp .tbButton, .mainApp .otherTools .toolButtons {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background: #FFF;
    color: #888;
    border-radius: 100%;
}

.mainApp .tbButton:hover, .mainApp .otherTools .toolButtons:hover {
    color: #555;
    background: #DDD;
}


/* THE CONVERSATION HISTORY CSS */

.mainApp .messageHistory {
    float: left;
    position: relative;
    width: calc(100% - 70px);
    height: calc(100vh - 250px);
    padding: 10px;
    margin: 5px 25px;
    /*border: 1px solid #ddd;*/
    border-radius: 25px;
    /*box-shadow:  0 1px 6px 0 rgba(32, 33, 36, .28);*/
    box-shadow: 0 0 2px 0 rgba(32, 33, 36, .28);
    background: white;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    /*justify-content: flex-end;*/
}

.mainApp .userBg {
    /*background: url("https://images.unsplash.com/photo-1463058837219-41e13a132b7d?dpr=1&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=&bg=") 100% center;*/
}

.mainApp .msg {
    position: relative;
    width: auto;
    min-height: 40px;
    min-width: 100px;
    max-width: 45%;
    height: auto;
    padding: 10px;
    /*padding-bottom: 35px;*/
    margin: 0px 5px;
    margin-bottom: 0px;
    background: #FFF;
    border-radius: 7px;
    clear: both;
}

.mainApp .msg:nth-last-child(1) {
    margin-bottom: 20px;
}

.mainApp .msg .timestamp {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #AAA;
    font-size: 10px;
    user-select: none;
}

.mainApp .messageReceived {
    float: left;
    text-align: left;
    background: rgba(221, 221, 221, 0.6);
}

.mainApp .messageSent {
    float: right;
    text-align: left;
    background: rgba(199, 211, 0, 0.3);
}

.mainApp .system {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 30px);
    background: rgba(180, 180, 180, 0.6);
}

.mainApp .system .message {
    padding-top: 0 !important;
}

/*.mainApp .messageSent > .timestamp, .mainApp .messageSent > .readStatus {*/
/*    bottom: 10px;*/
/*    right: 10px;*/
/*    color: darkgreen;*/
/*    user-select: none;*/
/*}*/

.mainApp .messageSent > .readStatus {
    position: absolute;
    bottom: 10px;
    right: 12px;
}


/* THE REPLY BAR CSS */

.mainApp .replyBar {
    width: 100%;
    height: 70px;
    float: left;
    background: #fff;
}

.mainApp .replyBar .attach {
    width: 70px;
    height: 70px;
    color: #777;
    background: #FFF;
    float: left;
}

.mainApp .replyBar .attach:hover {
    color: #555;
    background: #DDD;
}

.mainApp .replyBar .d45 {
    transform: rotate(45deg);
    font-size: 32px;
}

.mainApp .replyBar .replyMessage {
    width: calc(100% - 220px);
    float: left;
    height: 70px;
    padding: 0px 15px;
    font-size: 16px;
}

.mainApp .replyBar .otherTools {
    float: right;
    margin-right: 10px;
    padding-top: 5px;
    padding-left: 4px;
}

.mainApp .replyBar #inputWrapper .otherTools {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    top: 0;
}

.mainApp #inputWrapper {
    position: relative;
    padding: 15px 20px;
    font-family: VagRoundedRegular, serif;
    text-align: left;
}

.mainApp #input {
    background-color: white;
    border: none;
    border-radius: 50px;
    font-family: inherit;
    font-size: inherit;
    height: 40px;
    outline: none;
    padding-inline-end: 40px;
    padding-inline-start: 52px;
    position: relative;
    width: calc(100% - 35px);
    /*box-shadow:  0 1px 6px 0 rgba(32, 33, 36, .28);*/
    box-shadow: 0 0 2px 0 rgba(32, 33, 36, .28);
    color: black;
    padding-top: 4px;
}

.mainApp #input2 {
    background-color: white;
    border: none;
    border-radius: 50px;
    font-family: inherit;
    font-size: inherit;
    height: 40px;
    outline: none;
    padding-inline-end: 40px;
    padding-inline-start: 20px;
    position: relative;
    width: calc(100% - 35px);
    /*box-shadow:  0 1px 6px 0 rgba(32, 33, 36, .28);*/
    box-shadow: 0 0 2px 0 rgba(32, 33, 36, .28);
    color: black;
    padding-top: 4px;
}

.mainApp #icon {
    height: 100%;
    left: 35px;
    position: absolute;
    top: 0;
    color: #00374e;
}

.mainApp #icon2 {
    padding: 9px 2px 9px 0px;
    border-radius: 25px;
    cursor: pointer;
    height: 14px;
    width: 32px;
    position: absolute;
    right: 18px;
    top: 20px;
    color: #00374e;
}

.mainApp #icon3 {
    padding: 5px;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #C7D300;
}

.mainApp #icon3 .cls-1 {
    fill: #C7D300;
}

.mainApp #icon2:hover {
    background: rgba(221, 221, 221, 0.6);
}

.mainApp [type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    right: 20px;
    height: 10px;
    width: 10px;
    color: #00374e;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMTIzLjA1cHgiIGhlaWdodD0iMTIzLjA1cHgiIHZpZXdCb3g9IjAgMCAxMjMuMDUgMTIzLjA1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMjMuMDUgMTIzLjA1OyIKCSB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBmaWxsPSIjMDAzNzRlIiBkPSJNMTIxLjMyNSwxMC45MjVsLTguNS04LjM5OWMtMi4zLTIuMy02LjEtMi4zLTguNSwwbC00Mi40LDQyLjM5OUwxOC43MjYsMS43MjZjLTIuMzAxLTIuMzAxLTYuMTAxLTIuMzAxLTguNSwwbC04LjUsOC41CgkJYy0yLjMwMSwyLjMtMi4zMDEsNi4xLDAsOC41bDQzLjEsNDMuMWwtNDIuMyw0Mi41Yy0yLjMsMi4zLTIuMyw2LjEsMCw4LjVsOC41LDguNWMyLjMsMi4zLDYuMSwyLjMsOC41LDBsNDIuMzk5LTQyLjRsNDIuNCw0Mi40CgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPg==);
    background-size: 10px 10px;
    cursor: pointer;
}

.mainApp .expandIcon {
    position: absolute;
    right: 10px;
    top: 15px;
}

.mainApp .chatInfo .wrapper {
    /*width: calc(100% - 20px);*/
    width: calc(150%);
}

.mainApp .headerName, #top-container .headerName {
    text-align: left;
    font-family: VagRoundedBold, serif;
    font-size: 25px;
    color: #00374e;
    margin-top: 0;
    margin-left: 5px;
    margin-bottom: 0;
}

.mainApp .messageHistory .messageSenderName {
    /*position: absolute;*/
    /*top: 10px;*/
    /*left: 10px;*/
    color: #00374e;
    font-family: VagRoundedBold, serif;
}

.mainApp .messageHistory .message {
    /*position: absolute;*/
    /*top: 25px;*/
    /*left: 10px;*/
    word-break: break-all;
    padding-top: 5px;
    font-size: 14px;
}


.mainApp .messageHistory .message:after {
    content:"";
    display:inline-block;
    height:2px;
    width:35px; /* the value of padding */
    /*background:red; !* to illustrate *!*/
}
